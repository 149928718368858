import React, { useEffect, useState, useRef } from 'react';
import HeaderBar from '../../components/header-bar';
import BottomSheet from '../../components/bottom-sheet';
import Checkbox from '../../images/icons/icon-checkbox.svg';
import { navigate } from 'gatsby';
import { useAPI } from '../../utils/api';
import store, { actions, getters } from '../../redux/store';
import { useSelector } from 'react-redux';
import { useInput, useMultiSelectableList } from 'rooks';
import { showLoading, dismissLoading } from '../../utils/loading';

const QuestionSheet = ({ isShow, setShowAdd }) => {
  const api = useAPI();

  const [main, setMain] = useState([]);
  const [sub, setSub] = useState([]);
  const [subData, setSubData] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [submit, setSubmit] = useState({ category: '', sub_category: '' });

  const orderDetail = useSelector((state) => getters.getOrderDetail(state));

  const remarkInput = useInput('');

  const products = orderDetail.products.map((item) => {
    return item.productnumber;
  });

  const fastClickButton = useRef(false);

  const [selectProducts, selectProductsHandler] = useMultiSelectableList(
    [...products],
    [],
    true
  );

  const getMainCategory = (key) => {
    setSubmit((prevState) => ({
      ...prevState,
      category: key,
    }));
    setSub(subData[key]);
  };

  const getSubCategory = (key) => {
    setSubmit((prevState) => ({
      ...prevState,
      sub_category: key,
    }));
  };

  const sendHelp = (sendData) => {
    console.log('submit', sendData);
    api
      .sendOrderHelp(sendData)
      .then((res) => {
        if (!res) {
          fastClickButton.current = false;
          return;
        }
        setShowAdd(false);

        fastClickButton.current = false;

        showLoading();
        api
          .getOrderDetail(orderDetail.sfcc_transaction_no)
          .then((res) => {
            dismissLoading();
            if (!res) {
              return;
            }
            store.dispatch(actions.setOrderDetail(res));
          })
          .catch((error) => {
            dismissLoading();
            console.log('get order detail exception =', error);
          });
      })
      .catch((error) => {
        fastClickButton.current = false;
      });
  };

  useEffect(() => {
    // const result = selectProducts[0].find((item) => {
    //   return item === 0;
    // });
    // console.log(result);
    // if (result !== null && result !== undefined) {
    //   // 有全選
    //   // selectProducts[0] = [0, 1, 2, 3, 4];
    //   // selectProducts[1] = ['0', ...products];

    //   console.log(selectProducts);
    // }

    console.log(selectProducts);
    if (selectProducts[0].length != products.length) {
      setSelectAll(false);
    }
  }, [selectProducts]);

  const clickSelectAll = (event) => {};

  useEffect(() => {
    api
      .getOrderHelpCategory()
      .then((res) => {
        console.log('res', res);
        const mainArray = [];
        const subArray = [];
        const subObject = {};
        for (const element of res) {
          const obj = {};
          obj['name'] = element.name;
          obj['key'] = element.key;
          mainArray.push(obj);

          subObject[element.name] = element.childs;
        }

        setSubData(subObject);
        setMain(mainArray);

        if (submit.category === '' && submit.sub_category === '') {
          // default
          setSubmit((prevState) => ({
            ...prevState,
            category: res[0].name,
            sub_category: res[0].childs[0].name,
          }));
          setSub(subObject[res[0].name]);
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <BottomSheet isShow={isShow} setIsShow={setShowAdd}>
        <div className="content">
          <h2>請選擇問題類別</h2>
          <select
            onChange={(e) => {
              getMainCategory(e.target.value);
            }}
            value={submit.category}
          >
            {main &&
              main.map((item, index) => (
                <option
                  key={index}
                  value={item.name}
                  // selected={item.name === submit.category}
                >
                  {item.name}
                </option>
              ))}
          </select>
          <h2>請再細選您的問題</h2>
          <select
            onChange={(e) => {
              getSubCategory(e.target.value);
            }}
            value={submit.sub_category}
          >
            {sub &&
              sub.map((item, index) => (
                <option
                  key={index}
                  value={item.name}
                  // selected={item.name === submit.sub_category}
                >
                  {item.name}
                </option>
              ))}
          </select>
          <h2>訊息</h2>
          <div className="message-box">
            <div className="checkbox-list">
              <label className="checkbox">
                <input
                  type="checkbox"
                  key={selectAll}
                  checked={selectAll}
                  onChange={() => {
                    setSelectAll(!selectAll);
                  }}
                  // checked={selectProductsHandler.matchSelection({
                  //   index: 0,
                  // })}
                  // onChange={selectProductsHandler.toggleSelection({
                  //   index: 0,
                  // })}
                  onClick={selectProductsHandler.updateSelections({
                    indices: !selectAll
                      ? [...products.map((item, index) => index)]
                      : [],
                  })}
                />
                <span>全選</span>
              </label>
              {orderDetail.products.map((item, index) => (
                <label className="checkbox" key={index}>
                  <input
                    type="checkbox"
                    checked={selectProductsHandler.matchSelection({
                      index: index,
                    })}
                    onChange={selectProductsHandler.toggleSelection({
                      index: index,
                    })}
                  />
                  <span>{item.item_name}</span>
                </label>
              ))}
            </div>
            <div className="message">
              <textarea
                placeholder="敘述文字請勿超過500中文字(包含標點符號)"
                {...remarkInput}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="action-button">
          <button
            className=""
            onClick={() => {
              if (fastClickButton.current) return;
              fastClickButton.current = true;
              if (selectProducts[0].length === 0) {
                alert('請選擇商品');
                fastClickButton.current = false;
                return;
              }

              if (remarkInput.value === '') {
                alert('請輸入問題內容');
                fastClickButton.current = false;
                return;
              }

              sendHelp({
                sfcc_transaction_no: orderDetail.sfcc_transaction_no,
                userMsg: remarkInput.value,
                category: submit.category,
                sub_category: submit.sub_category,
                isAll:
                  selectProducts[0].length === products.length ? true : false,
                product: selectProducts[1],
              });
            }}
          >
            新增
          </button>
        </div>
      </BottomSheet>
      <style jsx>{`
        .content {
          overflow-y: scroll;
          background: #fff;
          box-sizing: border-box;
          padding: 0px 16px 180px;
        }
        h2 {
          padding-top: 16px;
          padding-bottom: 8px;
        }
        .message-box {
          border: 1px solid rgba(0, 0, 0, 0.25);
          box-sizing: border-box;
          border-radius: 3px;
        }
        .checkbox-list {
          padding: 0 16px;
          background: #f2f2f2;
        }
        label.checkbox {
          padding: 16px 0;
          border-bottom: 1px solid #ffffff;
        }
        textarea {
          box-sizing: border-box;
          width: 100%;
          min-height: 133px;
          resize: none;
          border: none;
          outline: none;
          padding: 8px 16px;
          font-size: 14px;
          line-height: 20px;

          color: rgb(51, 51, 51);
          &::placeholder {
            color: rgba(51, 51, 51, 0.5);
          }
        }
      `}</style>
    </>
  );
};
const UserCard = ({ orderId, data, products }) => {
  console.log('products =', products);
  return (
    <>
      <div className="user">
        <div className="question">
          <div>
            <div className="title">
              {data.category}
              <br />
              {data.sub_category}
            </div>
            <div className="selection product">
              {products.map((p, index) => (
                <p key={index}>
                  {p.item_name} / {p.unit_quantity}
                </p>
              ))}
              {/* 好媳婦天然植物肥皂 160GX4 /1入
              <br />
              高露潔抗敏感抗敏護齦2+1超值組 /1入 */}
            </div>
            <div className="selection all">
              {orderId} {data.isAll ? '全部商品' : ''}
            </div>
            <div className="content">{data.msg}</div>
          </div>
          <div className="date-time">{data.date}</div>
        </div>
        {data.reply !== null ? (
          <div className="reply">
            <span className="badge">回覆</span>
            {data.reply === '' ? '感謝您的提問，我們將盡快回覆。' : data.reply}
          </div>
        ) : (
          <></>
        )}
      </div>
      <style jsx>{`
        .user {
          width: 95%;
          margin-top: 20px;
          margin-left: auto;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.25);
          border-radius: 6px;
          box-sizing: border-box;
          font-family: Microsoft JhengHei;
        }
        .question {
          padding: 10px 9px 9px 10px;
          min-height: 122px;
          line-height: 21px;
          color: #3b3516;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-weight: bold;
            line-height: 21px;
            color: #3b3516;
            padding-bottom: 6px;
          }
          .date-time {
            text-align: right;
            font-size: 12px;
            line-height: 16px;
            color: #999999;
          }
        }
        .selection {
          border: 1px solid #e5e5e5;
          border-radius: 40px;
          padding: 6px 20px;
          font-size: 12px;
          line-height: 1.5;
          color: #3b3516;
          & ~ & {
            margin-top: 7.5px;
          }
          & ~ .content {
            padding-top: 8.5px;
          }
          .title {
            font-weight: bold;
          }
        }
        .content {
          font-size: 16px;
          line-height: 21px;
          color: #3b3516;
          padding-bottom: 8px;
        }
        .reply {
          border-top: 1px solid #dddddd;
          padding: 8.5px 10px 19px 8px;
          font-size: 16px;
          line-height: 1.5;
          color: #3b3516;
          .badge {
            padding: 1px 5px;
            border-radius: 40px;
            display: inline-block;
            background: #1430b3;
            font-size: 12px;
            line-height: 16px;
            color: #ffffff;
            margin-right: 4px;
          }
        }
      `}</style>
    </>
  );
};
const OrderHelp = () => {
  const [showAdd, setShowAdd] = useState(false);

  const orderDetail = useSelector((state) => getters.getOrderDetail(state));

  return (
    <>
      <div className="header-bar">
        <HeaderBar
          title={`詢問訂單: ${orderDetail.order_no}`}
          goBack={() => navigate(-1)}
        />
      </div>

      <div className="page">
        <div className="staff">請問有任何疑問嗎？</div>
        {orderDetail.messages.map((msg, index) => (
          <UserCard
            key={index}
            data={msg}
            orderId={orderDetail.order_no}
            products={msg.products}
          />
        ))}
      </div>
      <div className="action-button z-100">
        <button
          className="outlined"
          onClick={() => {
            setShowAdd(true);
          }}
        >
          請輸入您要諮詢的問題
        </button>
      </div>
      <QuestionSheet key={showAdd} isShow={showAdd} setShowAdd={setShowAdd} />
      <style jsx>{`
        .header-bar {
          width: 100%;
          z-index: 1;
          position: fixed;
          top: 0;
        }
        h2 {
          padding-top: 16px;
        }
        .page {
          height: calc(100% - 187px);
          overflow-y: scroll;
          padding: 86px 24px 16px 20px;
          position: relative;
        }
        .staff {
          display: inline-block;
          line-height: 23px;
          background: #f2f2f2;
          border-radius: 30px;
          padding: 6px 12.5px;
          margin-bottom: 6px;
        }
      `}</style>
    </>
  );
};

export default OrderHelp;
